import React, { Component, useCallback, useEffect, useState } from 'react'
import './UserSignup.sass'
import { BASE } from './../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../Biometric/StatesComponents/LoadingHandler'
import { fetchWithApiKey } from '../basedata/ApiCall'

interface Props {
  onResponse: (response: any) => void
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

function UserSignup({ onResponse }: Props) {
  const [currentStep, setCurrentStep] = useState(2)

  const [accessCode, setAccessCode] = useState('')

  const [registerError, setregisterError] = useState({
    status: false,
    message: ''
  })



  function Step2() {
    const {
      register,
      handleSubmit,
      setError,
      reset,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {

      const dateFormate = data.birthday.split('/')
      const validateDate = new Date(
        dateFormate[1] + '/' + dateFormate[0] + '/' + dateFormate[2]
      )
      const today = new Date()

      if (
        validateDate.getTime() > today.setHours(0, 0, 0, 0) ||
        validateDate.toString() === 'Invalid Date' ||
        data.birthday.length < 10
      ) {
        setError('birthday', {
          type: 'manual',
          message: 'Tu precisa informar uma data válida'
        })
        return false
      }

      if (!isValidEmail(data.email)) {
        setError('email', {
          type: 'manual',
          message: 'Tu precisa informar um E-mail válido'
        })
        return false
      }

      if (data.celphone.length < 12) {
        setError('celphone', {
          type: 'manual',
          message: 'Tu precisa informar um telefone válido'
        })
        return false
      }

      const currentFormData = data

      setCurrentStep(3)

      const properName = currentFormData.fullname.replace(/^\s+|\s+$/g, '').split(' ')

      fetchWithApiKey(`${BASE.api.base_url}${BASE.api.user_create}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: properName.shift(),
          externalId: currentFormData.document.replace(/^\s+|\s+$/g, ''),
          lastName: properName.join(' '),
          gender: '',
          document: currentFormData.document.replace(/^\s+|\s+$/g, ''),
          document_type: '',
          email: currentFormData.email.replace(/^\s+|\s+$/g, ''),
          phoneNumber: currentFormData.celphone
            .replace(/"/g, '')
            .replace(/'/g, '')
            .replace(/\(|\)/g, ''),
          birthDate:
            dateFormate[2] + '/' + dateFormate[1] + '/' + dateFormate[0],
          nationality: '',
          accessCode: `${accessCode}`,
          originCompanyId: 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1'
        })
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.onboarding_url) {
            const currentToken = response.onboarding_url.split('token=')[1]
            sessionStorage.setItem('token', currentToken.split('&orc=')[0])
            window.sessionStorage.setItem('doccheck', 'valid')
            sessionStorage.setItem('originCompany', 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1')

            return onResponse({
              token: currentToken,
              step: 'faceinformation'
            })
          }
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (currentStep !== 2) {
      return null
    }


    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <p>
            Certo! Agora precisamos que tu informe alguns dados para
            validarmos teu cadastro.
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Nome"
                {...(register('fullname', {
                  required: true
                }))}
              />
              <label className="form-label" htmlFor="name">
                Nome completo
              </label>
              {errors.name && (
                <span className="label-error">
                  Tu precisa informar um Nome válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Data nascimento"
                {...register('birthday')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskDate(value)
                }}
              />
              <label className="form-label" htmlFor="birthday">
                Data de nascimento
              </label>
              {errors.birthday && (
                <span className="label-error">
                  Tu precisa informar uma data válida
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="CPF"
                {...register('document', {
                  required: true
                })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskCPF(value)
                }}
              />
              <label className="form-label" htmlFor="document">
                CPF
              </label>
              {errors.document && (
                <span className="label-error">
                  Tu precisa informar um Documento válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <select
                className="form-control form-control-lg"
                {...register('gender')}
                >
                  <option disabled selected value="">Selecione</option>
                  <option value="male">Masculino</option>
                  <option value="female">Feminino</option>
                  <option value="na">Preferir não declarar</option>
              </select>
              <label className="form-label" htmlFor="gender">
                Gênero
              </label>
              {errors.gender && (
                <span className="label-error">
                  Tu precisa informar um Gênero válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="E-mail"
                {...register('email', {
                  required: true
                })}
              />
              <label className="form-label" htmlFor="email">
                E-mail
              </label>
              {errors.email && (
                <span className="label-error">
                  Tu precisa informar um E-mail válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Celular"
                {...register('celphone', {
                  required: true
                })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskPhone(value)
                }}
              />
              <label className="form-label" htmlFor="celphone">
                Celular
              </label>
              {errors.celphone && (
                <span className="label-error">
                  Tu precisa informar um telefone válido
                </span>
              )}
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input form-control-sm"
                {...register('valid')}
                id="valid"
                checked
              // defaultChecked
              />
              <label className="form-check-label" htmlFor="valid">
              Eu aceito os  {' '}
              <a href="/bepass_terms.pdf" rel="noreferrer" target="_blank">Termos de Uso e Política de Privacidade </a>, declaro que li e compreendi o  {' '}
              <a href="/bepass_privacy.pdf" rel="noreferrer" target="_blank">Aviso de Privacidade</a> e, em se tratando de cadastro de menor de idade, declaro ser o representante legal legítimo do menor e autorizo o tratamento dos dados biométricos do menor no   {' '}
              <a href="/bepass_consentment.pdf" rel="noreferrer" target="_blank">Termo de Consentimento para Tratamento de Dados Pessoais de Menores de Idade</a>, para o uso Bepass.
              </label>
              {/* {errors.celphone && (
                <span className="label-error">
                  Você precisa aceitar os termos de uso
                </span>
              )} */}
            </div>
          </div>

          <div className="form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Enviar"
              onClick={() => clearErrors()}
            />
          </div>
        </form>

        <ul className="step-counter">
          <li className="active"></li>
          <li className="active"></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  return (
    <>
      {/* Sign user up */}
      <Step2 />
      {/* Log user in */}
      {currentStep === 3 && <LoadingHandler />}
    </>
  )
}

export default UserSignup
